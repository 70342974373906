<template>
    <el-table v-if="tableData.length > 0" v-loading="$wait.is('loading.damages')" :data="tableData" style="width: 100%" element-loading-spinner="el-custom-spinner">
        <el-table-column :label="$t('title')" prop="title" />
        <el-table-column :label="$t('clients.address')" :show-overflow-tooltip="true">
            <template slot-scope="scope">
                {{ scope.row.street }}, {{ scope.row.city }} {{ scope.row.post_code }}
            </template>
        </el-table-column>
        <el-table-column prop="description" :label="$t('clients.description')">
            <template slot-scope="scope">
                <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="scope.row.description" placement="top" popper-class="w-112">
                    <p>
                        {{ scope.row.description && scope.row.description.length > 15
                            ? scope.row.description.slice(0, 15) + '...' : scope.row.description }}
                    </p>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="created_at" width="100" />
        <el-table-column width="80">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.edit')" placement="left">
                        <el-button type="primary" size="mini" @click="handleEdit(scope.row.id)">
                            <fa-icon :icon="['far', 'edit']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('clients.delete')" placement="top">
                        <el-button type="danger" size="mini" @click="handleDelete(scope.row.id)">
                            <fa-icon :icon="['fas', 'trash']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        tableData: {
            type:    Array,
            default: () => [],
        },
    },

    methods: {
        handleEdit(id) {
            this.$emit('handleEdit', id);
        },

        handleDelete(id) {
            this.$emit('handleDelete', id);
        },
    },
};
</script>
